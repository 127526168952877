import React, { useState } from "react";

const NewWorkoutForm = ({ onSubmit }) => {
  const [workoutName, setWorkoutName] = useState("");
  const [workoutNotes, setWorkoutNotes] = useState("");
  const [workoutType, setWorkoutType] = useState("strength");
  const [workoutGoal, setWorkoutGoal] = useState("default");

  const jwtToken = localStorage.getItem("jwtToken");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const workout = {
      workout_name: workoutName,
      workout_notes: workoutNotes,
      workout_type: workoutType,
      workout_goal: workoutGoal,
    };

    const response = await fetch("https://gymbro.no/backend/wp-json/gymbro/v1/create-workout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(workout),
    });

    if (response.ok) {
      const data = await response.json();
      const workoutId = data.workoutId;
      onSubmit(workoutId);
    } else {
      alert("Error creating workout!");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="new-workout-form">
      <label>Name</label>
      <input
        type="text"
        placeholder="Enter workout name"
        value={workoutName}
        onChange={(e) => setWorkoutName(e.target.value)}
      />
      <label>Notes</label>
      <input
        type="text"
        placeholder="Optional"
        value={workoutNotes}
        onChange={(e) => setWorkoutNotes(e.target.value)}
      />
      <label>Type</label>
      <select
        value={workoutType}
        onChange={(e) => setWorkoutType(e.target.value)}
      >
        <option value="strength">Strength</option>
        <option value="running">Running</option>
      </select>
      <label>Goal</label>
      <select
        value={workoutGoal}
        onChange={(e) => setWorkoutGoal(e.target.value)}
      >
        <option value="default">Default</option>
        <option value="relaxed">Relaxed</option>
        <option value="stamina">Increase Stamina</option>
        <option value="strength">Increase Strength</option>
      </select>
      <button type="submit">Create Workout</button>
    </form>
  );
};

export default NewWorkoutForm;
