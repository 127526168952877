import React from "react";
import NewWorkoutForm from "./../workouts/NewWorkoutForm";

const MyWorkouts = () => {
	const onSubmit = (workoutId) => {
		// Redirect the user to the workout detail page
		window.location.href = `/current-workout/${workoutId}`;
	};

	return (
		<div className="container">
		<h1>Ny treningsøkt</h1>
		
		<NewWorkoutForm onSubmit={onSubmit} />
		</div>
	);
};

export default MyWorkouts;
