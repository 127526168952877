import React, { useState, useEffect } from "react";
import ExerciseList from "./../values/Exercises";
import "./UserProfilePage.css";

const UserProfile = () => {
  const [profile, setProfile] = useState({ preferredTypes: "", goals: "" });
  const [exercises, setExercises] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [isExerciseSelectorOpen, setIsExerciseSelectorOpen] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch("https://gymbro.no/backend/wp-json/gymbro/v1/user-profile", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setProfile({
            preferredTypes: data.preferredTypes || "",
            goals: data.goals || "",
          });

          // Fetch user's selected exercises
          const exercisesResponse = await fetch(
            "https://gymbro.no/backend/wp-json/gymbro/v1/user-selected-exercises",
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
              },
            }
          );
          if (exercisesResponse.ok) {
            const exerciseData = await exercisesResponse.json();
            setSelectedExercises(exerciseData);
          }
        } else {
          console.error("Error fetching profile:", response);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  const handleSave = async () => {
    try {
      const response = await fetch("https://gymbro.no/backend/wp-json/gymbro/v1/user-profile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify({ ...profile, selectedExercises }),
      });

      if (response.ok) {
        alert("Profile updated successfully!");
      } else {
        console.error("Error updating profile:", response);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const toggleExerciseSelector = () => setIsExerciseSelectorOpen(!isExerciseSelectorOpen);

  const handleSelectExercise = (exercise) => {
    setSelectedExercises((prev) =>
      prev.includes(exercise) ? prev.filter((e) => e !== exercise) : [...prev, exercise]
    );
  };

  const handleSelectAll = () => {
    setSelectedExercises(ExerciseList.map((exercise) => exercise.Name));
  };

  return (
    <div className="container">
      <h1>User Profile</h1>
      <label>Preferred Workout</label>
      <input
        type="text"
        placeholder="E.g., strength, cardio"
        value={profile.preferredTypes}
        onChange={(e) => setProfile({ ...profile, preferredTypes: e.target.value })}
      />
      <label>General Goals</label>
      <input
        type="text"
        placeholder="E.g., Increase strength"
        value={profile.goals}
        onChange={(e) => setProfile({ ...profile, goals: e.target.value })}
      />
      <button onClick={toggleExerciseSelector}>Select Exercises</button>
      {isExerciseSelectorOpen && (
        <div className="exercise-selector">
          <button onClick={handleSelectAll}>Select All</button>
          <div className="exercise-list">
            {ExerciseList.map((exercise) => (
                <div key={exercise.Name} className="exercise-item">
                    <input
                        type="checkbox"
                        id={`exercise-${exercise.Name}`} // Unique ID
                        checked={selectedExercises.includes(exercise.Name)}
                        onChange={() => handleSelectExercise(exercise.Name)}
                        name={exercise.Name}
                    />
                    <label htmlFor={`exercise-${exercise.Name}`}>
                        <img
                        src={`https://gymbro.no/assets/icons/exercises/${exercise.Icon}`}
                        alt={exercise.Name}
                        />
                        {exercise.Name}
                    </label>
                    </div>

            ))}
          </div>
        </div>
      )}
      <button onClick={handleSave}>Save Profile</button>
    </div>
  );
};

export default UserProfile;
