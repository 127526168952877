import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchWorkouts from '../api/FetchWorkouts';
import WorkoutDeleteButton from './../api/DeleteWorkout';

const WorkoutList = () => {
  const { workouts, isLoading, error } = useFetchWorkouts();
  const [selectedIndex, setSelectedIndex] = useState(null); // Initialize selectedIndex as null

  // Function to format the date
  const formatDate = (date) => {
    const workoutDate = new Date(date);
    return workoutDate.toLocaleDateString('nb-NO', { weekday: 'long', day: 'numeric', month: 'long' });
  };

  if (isLoading) {
    return <div className="list-container-workouts">Laster...</div>;
  }

  if (error) {
    return <div className="list-container-workouts">Feil: {error.message}</div>;
  }

  // Initialize a variable to keep track of the current date
  let currentDate = null;

  return (
    <div className="list-container-workouts">
      {workouts.entries.map((workout, index) => {
        // Get the formatted date for the current workout
        const formattedDate = formatDate(workout.gb_workout_created);

        // Check if the date has changed, and if so, display it as a header
        if (formattedDate !== currentDate) {
          currentDate = formattedDate; // Update the current date
          return (
            <React.Fragment key={index}>
              <div className="date-header">{formattedDate}</div>
              <div className="list-row-workouts" key={workout.id}>
                <Link to={`/current-workout/${workout.id}`}>
                  <img src={`https://gymbro.no/assets/icons/exercises/${workout.gb_workout_type}-white.png`} alt="Workout" />
                  <p>{workout.gb_workout_name}</p>
                  <p>{formattedDate}</p>
                </Link>
                <WorkoutDeleteButton workoutId={workout.id} />
              </div>
            </React.Fragment>
          );
        } else {
          // If the date hasn't changed, display the workout without a header
          return (
            <div className="list-row-workouts" key={workout.id}>
              <Link to={`/current-workout/${workout.id}`}>
                <img src={`https://gymbro.no/assets/icons/exercises/${workout.gb_workout_type}-white.png`} alt="Workout" />
                <p>{workout.gb_workout_name}</p>
                <p>{formattedDate}</p>
              </Link>
              <WorkoutDeleteButton workoutId={workout.id} />
            </div>
          );
        }
      })}
    </div>
  );
};

export default WorkoutList;
