import React, { useState, useEffect } from "react";
import SetDeleteButton from "./../../api/DeleteSet";
import axios from "axios";
import ExerciseList from "./../../values/Exercises";
import SetListTemplate from "./SetTemplateOne";

const SetList = ({ workoutId }) => {
  const [workoutData, setWorkoutData] = useState(null);

  useEffect(() => {
    fetchWorkoutData();
  }, [workoutId]);

  const fetchWorkoutData = async () => {
    try {
      const response = await axios.post(
        `https://gymbro.no/backend/wp-json/gymbro/v1/current-workout/${workoutId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        setWorkoutData(data);
      } else {
        // Handle the error case if needed
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to find exercise icon based on exercise name
  const findExerciseIcon = (exerciseName) => {
    const exercise = ExerciseList.find((item) => item.Name === exerciseName);
    return exercise ? exercise.Icon : null;
  };

  const findSetTemplate = (exerciseName) => {
    const exercise = ExerciseList.find((item) => item.Name === exerciseName);
    return exercise ? exercise.Type : null;
  };

  if (workoutData === null) {
    return null;
  }

    // Organize sets by type
    const setsByType = {};
    workoutData.sets.forEach((set) => {
        if (!setsByType[set.gb_set_workout_type]) {
            setsByType[set.gb_set_workout_type] = [];
        }
        setsByType[set.gb_set_workout_type].push(set);
    });

  return (
    <div className="list-row-container">

        {Object.keys(setsByType).map((setType) => (
            
            <div key={setType} className="list-row-parent">
                <div className="list-row-header">
                <div className="list-row-icon">
                            <img
                                src={`https://gymbro.no/assets/icons/exercises/${findExerciseIcon(setType)}`}
                                alt="Exercise Icon"
                            />
                        </div>
                    <p></p>
                    
                </div>

				<div className="list-row-body">
				<p className="list-row-type-title">{setType}</p>
                {setsByType[setType].map((set) => {
                  
                    const setTemplate = findSetTemplate(set.gb_set_workout_type);

                    return (
						<div className="list-row" key={set.id}>
							
								{ setTemplate && (
									<SetListTemplate exerciseType={set} template={setTemplate} />
								)}
		
								<div className="list-row-set-btns">
									<SetDeleteButton setId={set.id} onSetDeleted={fetchWorkoutData} />
								</div>
						</div>
							
						);
					})}
				</div>
			</div>
			))}
    </div>
  );
};

export default SetList;
