import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NewSet from '../workouts/NewSet';
import axios from 'axios';
import ExerciseList from '../values/Exercises';
import SetDeleteButton from '../api/DeleteSet';
import ErrorModal from '../modals/ErrorModal';
import SetList from '../sections/setList/SetList';

const CurrentWorkout = () => {
  const { id } = useParams();
  const [workoutData, setWorkoutData] = useState(null);
  const [profile, setProfile] = useState(null);
  const [currentWorkout, setCurrentWorkout] = useState(null);
  const [aiSuggestion, setAISuggestion] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const jwtToken = localStorage.getItem('jwtToken');
  const [selectedExercises, setSelectedExercises] = useState([]);



  // Fetch workout data on component mount or when ID changes
  useEffect(() => {
    fetchWorkoutData();
    fetchProfileData();
    fetchSelectedExercises();
  }, [id]);

  // Fetch current workout data
  const fetchWorkoutData = async () => {
    try {
      const response = await axios.post(`https://gymbro.no/backend/wp-json/gymbro/v1/current-workout/${id}`, null, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setWorkoutData(data);
        setCurrentWorkout(data); // Update currentWorkout dynamically
      } else {
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching workout data:", error);
      setIsErrorModalOpen(true);
    }
  };

  // Fetch user profile data
  const fetchProfileData = async () => {
    try {
      const response = await axios.get("https://gymbro.no/backend/wp-json/gymbro/v1/user-profile", {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setProfile(data);
        localStorage.setItem('profile', JSON.stringify(data));
      } else {
        console.error("Error fetching profile data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  // fetch user selected exercises
  const fetchSelectedExercises = async () => {
    try {
      const response = await axios.get("https://gymbro.no/backend/wp-json/gymbro/v1/user-selected-exercises", {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setSelectedExercises(data);
      } else {
        console.error("Error fetching selected exercises:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching selected exercises:", error);
    }
  };

  // Function to find exercise icon based on exercise name
  const findExerciseIcon = (exerciseName) => {
    const exercise = ExerciseList.find((item) => item.Name === exerciseName);
    return exercise ? exercise.Icon : null;
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  // Handle AI workout suggestion
  const handleAISuggestion = async () => {
    try {
      // Function to clean and transform workout data
      const cleanWorkoutData = (workout) => {
        return workout.sets.map((set) => {
          const cleanedSet = {};
          for (const [key, value] of Object.entries(set)) {
            if (value !== null) {
				 // Skip 'userid' and 'created' keys
		

				switch (key) {
					case "gb_set_workout_type":
					cleanedSet["exercise"] = value;
					break;
					case "gb_set_equipment":
					cleanedSet["equipment"] = value;
					break;
					case "gb_set_weight":
					cleanedSet["weight"] = value;
					break;
					case "gb_set_repetitions":
					cleanedSet["repetitions"] = value;
					break;
					case "gb_set_time":
					cleanedSet["time"] = value;
					break;
					case "gb_set_time_unit":
					cleanedSet["time_unit"] = value;
					break;
					case "gb_set_distance":
					cleanedSet["distance"] = value;
					break;
					case "gb_set_distance_unit":
					cleanedSet["distance_unit"] = value;
					break;
					case "gb_set_height":
					cleanedSet["height"] = value;
					break;
					case "gb_set_height_unit":
					cleanedSet["height_unit"] = value;
					break;
					case "gb_set_tiredness":
					cleanedSet["user_tiredness"] = value;
					break;
					default:
					cleanedSet[key.replace(/^gb_set_/, "")] = value; // Fallback for other fields
				}
				}
          }
          return cleanedSet;
        });
      };
  
      // Preprocess current workout data
      const processedWorkout = {
        ...currentWorkout,
        sets: cleanWorkoutData(currentWorkout),
      };
  
      const response = await fetch("https://gymbro.no/backend/wp-json/gymbro/v1/suggest-workout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          profile,
          workout: processedWorkout,
          selected_exercises: selectedExercises,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch suggested workout.");
      }
  
      const data = await response.json();
      console.log("Suggested Workout:", data);
      setAISuggestion(data); // Display AI suggestion
    } catch (error) {
      console.error("Error suggesting workout:", error);
    }
  };
  

  const handleAcceptSuggestion = () => {
    // Add logic to accept the suggestion and save it
    setAISuggestion(null);
    fetchWorkoutData();
  };

  if (workoutData === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      	<h1>{workoutData.workoutName}</h1>
		
		<div className="list-container">
			<div className="list-btn-container">
				<button className="blue-btn" onClick={handleAISuggestion}>Get AI Suggestion</button>
				<button className="blue-btn" onClick={openPopup}>Legg til øvelse</button>
			
			</div>

			{aiSuggestion && (
				<div className="ai-suggestion-modal">
					<h2>AI Suggestion</h2>
					<p>{aiSuggestion.exercise}</p>
					<p>Type: {aiSuggestion.type_of_exercise}</p>
					<ul>
					{aiSuggestion.sets.map((set, index) => (
						<li key={index}>
						{set.weight && `Weight: ${set.weight} kg`}
						{set.repetitions && `, Repetitions: ${set.repetitions}`}
						{set.distance && `Distance: ${set.distance} meters`}
						{set.laps && `, Laps: ${set.laps}`}
						</li>
					))}
					</ul>
					<button onClick={handleAcceptSuggestion}>Accept</button>
					<button onClick={() => setAISuggestion(null)}>Cancel</button>
				</div>
			)}

			<NewSet isOpen={isPopupOpen} onClose={closePopup} workoutId={id} />
			<SetList workoutId={id} />

			{isErrorModalOpen && (
			<ErrorModal
				title="Feil"
				content="Det er en feil med tilkobling til API"
				onClose={closeErrorModal}
			/>
			)}
      	</div>
    </div>
  );
};

export default CurrentWorkout;
