// ErrorModal.js
import React, { useState } from 'react';
import './../../styles/ErrorModal.css';

const ErrorModal = ({ title, content, onClose }) => {
  const [showError, setShowError] = useState(true);

  const closeErrorModal = () => {
    setShowError(false);
    onClose(); // Call the provided onClose function
  };

  return (
    <div className={`error-modal-background ${showError ? 'visible' : ''}`} onClick={closeErrorModal}>
      <div className="error-modal" onClick={(e) => e.stopPropagation()}>
        <div className="error-modal-header">
          <span className="error-modal-title">{title}</span>
          <span className="error-modal-close" onClick={closeErrorModal}>
            &#x2716;
          </span>
        </div>
        <div className="error-modal-content">{content}</div>
      </div>
    </div>
  );
};

export default ErrorModal;
