import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MyDashboard from "./components/pages/MyDashboard";
import MyWorkouts from "./components/pages/MyWorkouts";
import CurrentWorkout from "./components/pages/CurrentWorkout";
import AuthWrapper from "./components/auth/AuthWrapper";
import NewWorkoutForm from "./components/pages/CreateWorkout";
import UserProfile from "./components/pages/UserProfilePage";
import Header from "./components/sections/Header";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const jwtToken = localStorage.getItem("jwtToken");

  useEffect(() => {
    setIsAuthenticated(!!jwtToken);
  }, [jwtToken]);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/my-dashboard" element={<AuthWrapper isAuthenticated={isAuthenticated}><MyDashboard /></AuthWrapper>} />
        <Route path="/my-workouts" element={<AuthWrapper isAuthenticated={isAuthenticated}><MyWorkouts /></AuthWrapper>} />
        <Route path="/create-workout" element={<AuthWrapper isAuthenticated={isAuthenticated}><NewWorkoutForm /></AuthWrapper>} />
        <Route path="/current-workout/:id" element={<AuthWrapper isAuthenticated={isAuthenticated}><CurrentWorkout /></AuthWrapper>} />
        <Route path="/user-profile" element={<AuthWrapper isAuthenticated={isAuthenticated}><UserProfile /></AuthWrapper>} />
        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/register" element={<Register setIsAuthenticated={setIsAuthenticated} />} />
      </Routes>
    </Router>
  );
}

export default App;
